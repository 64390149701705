/**
 * Composable for Google Analytics ecommerce events.
 * This composable provides functions to send ecommerce-related events to Google Analytics.
 *
 * See doc, https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtag#make_a_purchase_or_issue_a_refund
 *
 * @returns {Object} An object containing methods for sending ecommerce events.
 * @returns {Function} sendPurchase - Function to send a purchase event to Google Analytics.
 *
 *
 *
 */

export default function useGoogleAnalyticsEvent() {
  if (import.meta.server) return

  const debug_mode = import.meta.dev ? true : false

  // https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtag#view_item_list
  const sendViewItemList = (itemlist) => {
    gtag('event', 'view_item_list', {
      item_list_id: itemlist.id,
      item_list_name: itemlist.name,
      items: buildItems(itemlist.items),
    })
  }

  // https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtag#view_item
  const sendViewItem = (item) => {
    if (!item?.variants) return
    const variant = item.variants.find((v) => v.selected)
    if (!variant) return
    gtag('event', 'view_item', {
      debug_mode: debug_mode,
      currency: item.currency.code,
      value: variant.price,
      items: [
        {
          item_id: `${variant.id}`,
          item_name:
            variant.variant_group_name ?? variant.short_name ?? variant.name,
          discount:
            variant.pvp > variant.price ? variant.pvp - variant.price : 0,
          item_brand: item.manufacturer_name,
          item_variant: `${variant.attribute_id}`,
          price: variant.price,
        },
      ],
    })
  }

  // https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtag#view_cart
  const sendViewCart = (cart, currency) => {
    const total = cart.totals.find((total) => total.type === 'total').value
    gtag('event', 'view_cart', {
      debug_mode: debug_mode,
      currency: currency.code,
      value: total,
      items: buildItems(cart.items),
    })
  }

  // https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtag#add_to_cart
  const sendAddToCart = (cart, currency) => {
    const total = cart.totals.find((total) => total.type === 'total').value
    gtag('event', 'add_to_cart', {
      debug_mode: debug_mode,
      currency: `${currency.code}`,
      value: total,
      items: buildItems(cart.items),
    })

    const { userData } = useLocalUser()
    if (userData.value) {
      gtag('set', 'user_data', {
        email: `${userData.value.email}`,
        phone_number: `${userData.value?.phone}`,
      })
    }

    gtag('event', 'conversion', {
      send_to: 'AW-16544718856/JMx_CNSy2d0ZEIjAkdE9',
      value: total,
      currency: `${currency.code}`,
      transaction_id: cart?.id,
    })
  }

  // https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtag#remove_from_cart
  const sendRemoveToCart = (cart, currency) => {
    const total = cart.totals.find((total) => total.type === 'total').value
    gtag('event', 'remove_from_cart', {
      debug_mode: debug_mode,
      currency: `${currency.code}`,
      value: total,
      items: buildItems(cart.items),
    })

    const { userData } = useLocalUser()
    if (userData.value) {
      gtag('set', 'user_data', {
        email: `${userData.value.email}`,
        phone_number: `${userData.value?.phone}`,
      })
    }

    gtag('event', 'conversion', {
      send_to: 'AW-16544718856/JMx_CNSy2d0ZEIjAkdE9',
      value: total,
      currency: `${currency.code}`,
      transaction_id: cart?.id,
    })
  }

  // https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtag#login
  const sendLogin = (method: string) => {
    gtag('event', 'login', {
      debug_mode: debug_mode,
      method: method,
    })
  }

  // https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtag#begin_checkout
  const sendBeginCheckout = (checkout) => {
    const total = checkout?.totals.find((total) => total.type === 'total').value
    gtag('event', 'begin_checkout', {
      currency: `${checkout.currency.code}`,
      value: total,
      coupon: checkout.coupons.length ? checkout.coupons[0] : '',
      items: buildItems(checkout.shipments[0].items),
    })

    const { userData } = useLocalUser()
    if (userData.value) {
      gtag('set', 'user_data', {
        email: `${userData.value.email}`,
        phone_number: `${userData.value?.phone}`,
      })
    }

    gtag('event', 'conversion', {
      send_to: 'AW-16544718856/arqrCMT_2N0ZEIjAkdE9',
      value: total,
      currency: `${checkout.currency.code}`,
    })
  }

  // https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtag#purchase
  const sendPurchase = (order) => {
    const shipping = order?.totals?.find(
      (total) => total.type === 'shipping'
    ).value
    const orderData = {
      debug_mode: debug_mode,
      transaction_id: `${order?.order_id}`,
      value: order?.total,
      shipping: shipping,
      currency: `${order?.currency?.code}`,
      items: buildItems(order?.shipments[0].items),
    }

    gtag('event', 'purchase', orderData)

    const { userData } = useLocalUser()
    if (userData.value) {
      gtag('set', 'user_data', {
        email: `${userData.value.email}`,
        phone_number: `${userData.value?.phone}`,
        first_name: `${userData.value?.firstname}`,
        last_name: `${userData.value?.lastname}`,
      })
    }

    gtag('event', 'conversion', {
      send_to: 'AW-16544718856/-InQCNLU2d0ZEIjAkdE9',
      transaction_id: `${order?.order_id}`,
      value: order?.total,
      currency: `${order?.currency?.code}`,
    })
  }

  return {
    sendViewItemList,
    sendViewItem,
    sendViewCart,
    sendAddToCart,
    sendRemoveToCart,
    sendLogin,
    sendBeginCheckout,
    sendPurchase,
  }
}

const buildItems = (items) => {
  return items.map((item) => ({
    item_id: `${item.product_id}`,
    name: item.variant_group_name ?? item.name,
    discount: item.pvp > item.price ? item.pvp - item.price : 0,
    item_brand: item.manufacturer_name,
    item_variant: `${item.attribute_id}`,
    price: item.price,
    quantity: item.qty ?? 1,
  }))
}
