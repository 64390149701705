import { useScriptClarity, useScript, defineNuxtPlugin } from '#imports'

export default defineNuxtPlugin({
  name: "scripts:init",
  env: { islands: false },
  parallel: true,
  setup() {
    const clarity = useScriptClarity({"id":"pytuevhfox"})
    return { provide: { $scripts: { clarity } } }
  }
})